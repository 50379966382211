<template>
  <BaseModal
    :heading="$t('admin.treatwell.kyc_modal.title')"
    useForm
    @submit="goToKYCFlow"
    @close="close"
  >
    <BaseVisualBanner
      mb
      variant="small"
      icon="external_link"
      :heading="$t('admin.treatwell.kyc_modal.banner.heading')"
      :description="$t('admin.treatwell.kyc_modal.banner.description')"
      :imagePath="$screen !== 's' ? '/img/admin/verification-process.svg' : ''"
      :primaryAction="$t('admin.treatwell.kyc_modal.banner.primary_action')"
      @primaryAction="emit('learnMore')"
    />
    <BaseHeading mb icon="alert">
      {{ $t('admin.treatwell.kyc_modal.heading') }}
    </BaseHeading>
    <BaseText size="s">
      {{ $t('admin.treatwell.kyc_modal.text') }}
    </BaseText>
    <template #footer>
      <BaseButton
        v-if="$screen !== 's'"
        color="inverted"
        @click="close"
        v-test="'tw-onboarding-dismiss-kyc'"
      >
        {{ $t('admin.treatwell.kyc_modal.do_this_later') }}
      </BaseButton>
      <BaseButton
        submitForm
        icon="external_link"
        v-test="'tw-onboarding-begin-kyc'"
        >{{ $t('admin.treatwell.kyc_modal.start_verification') }}</BaseButton
      >
      <BaseButton
        v-if="$screen === 's'"
        color="inverted"
        @click="close"
        v-test="'tw-onboarding-dismiss-kyc'"
      >
        {{ $t('admin.treatwell.kyc_modal.do_this_later') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { inject } from 'vue';

const emit = defineEmits(['submit', 'learnMore', 'close']);

const mixpanel = inject<any>('mixpanel');

const close = () => {
  mixpanel.track('kyc_start_modal_dismissed');
  emit('close');
};
const goToKYCFlow = () => {
  emit('submit');
  close();
};

mixpanel.track('kyc_start_modal_shown');
</script>
